/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

.example-spacer {
  flex: 1 1 auto;
}

.primary-color {
  color: #00629b !important;
}

.app-toolbar-fixed {
  background: #00629b;
  color: #fff;
  height: 55px !important;
  position: sticky !important;
  position: -webkit-sticky !important; /* For macOS/iOS Safari */
  top: 0 !important; /* Sets the sticky toolbar to be on top */
  z-index: 1000 !important; /* Ensure that your app's content doesn't overlap the toolbar */
}

.toolbar-fixed {
  background: #fff;
  height: 55px !important;
  position: sticky !important;
  position: -webkit-sticky !important; /* For macOS/iOS Safari */
  top: 0 !important; /* Sets the sticky toolbar to be on top */
  z-index: 1000 !important; /* Ensure that your app's content doesn't overlap the toolbar */
}

.toolbar {
  background: #00629b;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 55px !important;
}

* {
  font-family: "Open Sans", sans-serif;
  // font-weight: 400;
}

html,
body {
  min-height: 100%;
  margin: 0;
  // background-color: #fafafa;
}

.company-image {
  width: 130px;
}

.add-customer-dialog {
  mat-dialog-container {
    padding: 0 !important;
  }
}

.modify-customer-dialog {
  mat-dialog-container {
    padding: 0 !important;
  }
}

.add-user-dialog {
  mat-dialog-container {
    padding: 0 !important;
  }
}

.equipment-dialog {
  mat-dialog-container {
    padding: 0 !important;
  }
}

.modify-user-dialog {
  mat-dialog-container {
    padding: 0 !important;
  }
}

.company-logo {
  text-align: center;
  width: 100%;
  margin-left: -100px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: grey;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: grey;
}

.is-open > .is-active {
  z-index: 2;
  position: relative !important;
}

.sidenav-container {
  overflow: visible;
  margin-top: 64px;
}

.mat-drawer-content {
  overflow: visible;
  height: 100%;
}

a,
u {
  text-decoration: none;
  color: inherit;
}

.mat-toolbar.mat-primary {
  position: fixed;
  top: 0;
  z-index: 1000;
}

.active-list-item {
  color: #00629b !important;
}

.menu-icon-spacing {
  margin-left: 25px;
}

.mat-menu-item.is-active {
  color: #00629b !important;
}

.toolbar-height-50 {
  height: 50px !important;
  background: white !important;
  color: #00629b !important;
  font-size: 20px !important;
}

.toolbar-height-50-1 {
  height: 50px !important;
  color: #00629b !important;
  font-size: 20px !important;
}

.toolbar-spacer {
  flex: 1 1 auto;
}

.mat-button-focus-overlay {
  background-color: transparent;
}

.cdk-overlay-pane {
  min-width: 100px !important;
}

.service-request-filter {
  //mat-dialog-container {
  // padding: 0px !important;
  // background-color: #fff !important;
  // border-width: 1px !important;
  // border-color: #bfbfbf !important;
  // border-radius: 0.25rem !important;
  // box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  // }

  // mat-dialog-content { 
  //   padding: 16px !important;
  // }
}

.lopa-message {
  mat-dialog-container {
    padding: 0px !important;
    background-color: rgb(232, 234, 246) !important;
    border-width: 1px !important;
    border-color: #bfbfbf !important;
    border-radius: 0.25rem !important;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
    max-height: 60vh !important;
  }
}

.extension-fields-dialog .mat-dialog-container {
  padding: 0 !important;
}

.mat-select-panel {
  margin-top: 30px !important;
  margin-left: 20px !important;
}

.mat-list-base .mat-list-item .mat-line {
  white-space: normal !important;
}

// mat-sidenav {
//   background-color: #fafafa !important;
// }

.mat-horizontal-stepper-header {
  height: 50px !important;
}

.mat-step-header:hover {
  background-color: white !important;
}
.mat-step-header.cdk-program-focused {
  background-color: white;
}
.mat-step-header.cdk-mouse-focused {
  background-color: white;
}
.mat-step-header.cdk-focused {
  background-color: white;
}

.mat-step-header .mat-step-icon-selected {
  background-color: #00629b !important;
  color: white;
  + .mat-step-label {
    color: #00629b !important;
  }
}

.mat-menu-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.mat-select-disabled .mat-select-value {
  color: black !important;
  cursor: no-drop !important;
}

.mat-icon-button.mat-button-disabled {
  cursor: no-drop !important;
}

.mat-input-element:disabled {
  color: black !important;
  cursor: no-drop !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 35px !important;
  padding: 0 15px !important;
}

.mat-list-base {
  padding-top: 0px !important;
}

ngx-dropzone-preview {
  margin: auto !important;
}

.mat-step-header {
  pointer-events: none !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #00629b !important;
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #00629b !important;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -17px !important;
  top: 11px !important;
}

.mat-menu-panel {
  width: 250px !important;
  padding: 10px !important;
  margin-left: -100px !important;
  border: 1px solid #00629b !important;
  max-height: 300px !important;
  // box-shadow: inset 0 0 6px rgba(70, 70, 70, 0.5) !important;
}

.mat-ment-parts {
  margin-top: 30px !important;
}

.mat-drawer-container {
  background-color: white !important;
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: #ced4da !important;
}


.success-button {
  background: rgb(37, 155, 36);
  color: white;
}

.mat-nav-list .mat-list-item {
  height: 44px !important;
}


mat-form-field.fit-content {
  .mat-select-placeholder {
    min-width: 180px !important;
  }

  .mat-form-field-infix {
    width: fit-content;
    min-width: fit-content;
  }

  .mat-form-field {
    width: auto !important;
  }

  .mat-select-value {
    max-width: 100% !important;
    width: auto !important;
  }
}

.bottomStick{position:absolute; bottom:0; width: 100%;}
.bottomRelative{position:relative; width: 100%;}

th.mat-header-cell{
  font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.side-menu-height {
  height: 90%;
  overflow: auto;
}